// React
import React from "react"

// Gatsby
import {
    graphql
} from "gatsby"

// Images
import Image from "gatsby-image"

// MDX
import MdxWrapper from "../components/mdx/MdxWrapper"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"
//import LinkExt from "../components/linkext"

// FontAwesome
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Styles
import "../styles/pages/SuperSnowTubesPage.scss"

// Component for the  snowtubes page
const SuperSnowTubesPage = ({
    data,
    location
}) => { 

    // MDX data for this page
    const pageData = data.mdx;
    
    // Frontmatter for this page
    const {
        title: pageTitle,
        category: pageCategory,
        // TODO: Replace this date in the md file with an automatic last updated date feature based on the file changed attribute ?
        date: pageDate,
        description: pageDescription,
    } = pageData.frontmatter;

    // Page thumbnail
    let pageThumbnail = data.pageIllustration.childImageSharp.fluid;
    //let shareThumbnail = data.pageIllustration.childImageSharp.resize?.src;

    // Devlog thumbnails (imported via the graphql page query)
    //const thumbnails = data.allFile.edges;
    
    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={pageTitle}
                description={pageDescription}
                pathname={location.pathname}
                // thumbnail={shareThumbnail}
            />

            {/* Page */}
            <article id="supersnowtubes-page">

                {/* Page header */}
                <header className="page-header page-header-with-thumbnail">

                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {pageCategory}
                        </div>

                        {/* Page title */}
                        <h1 className="page-title">
                            {pageTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {pageDescription}
                        </p>

                    </section>

                    {/* Image on rhs */}
                    <section className="page-image">
                        {pageThumbnail &&
                            <Image fluid={pageThumbnail} />
                        }
                    </section>

                </header>
                    
                {/* Page main */}
                <main className="page-main">

                    {/* Page body */}
                    <section className="page-body">

                        {/* MDX */}
                        <MdxWrapper>
                            {pageData.body}
                        </MdxWrapper>

                    </section>                    
                    
                    {/* Grid of devlogs */}
                    <section className="devlog-grid">
                        
                        - More info coming soon -

                    </section>

                </main>

                {/* Page footer */}
                <footer className="page-footer">

                    Last updated: {pageDate}

                </footer>
                
            </article>

        </Layout>
    )
}

// Export component
export default SuperSnowTubesPage

// GraphQL query
export const pageQuery = graphql`

    query SuperSnowTubesPageQuery {

        pageIllustration: file(
            absolutePath: {
                #regex: "/homepage-illustration-snowtubes.png/"
                regex: "/sst/sst-characters-thumb.png/"
            }
        ) {
            childImageSharp {
                fluid( maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                }
                resize(width: 1200, height: 1200) {
                    src
                }       
            }
        }              

        allFile(
            sort: { 
                fields: name,
                order: ASC
            }
            filter: { 
                relativeDirectory: { eq: "snowtubes" },
                extension: { eq: "png" }
            }
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }

        mdx(
            fileAbsolutePath: {
                glob: "**/content/pages/**/supersnowtubes.mdx"
            }
        ) {
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                category
                date(formatString: "MMM D, YYYY")
                description
            }      
        }         
        
    }
`
